import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["src", "title"]
const _hoisted_3 = { class: "flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelWithColon = _resolveComponent("LabelWithColon")!
  const _component_ModalTemplate = _resolveComponent("ModalTemplate")!

  return (_openBlock(), _createBlock(_component_ModalTemplate, {
    "modal-id": _ctx.modalId,
    title: "Detail Kategori"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LabelWithColon, {
          label: "Kode",
          value: _ctx.detailCategory.kode,
          loading: _ctx.loading,
          margin: "mb-2"
        }, null, 8, ["value", "loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Title",
          value: _ctx.detailCategory.title,
          loading: _ctx.loading,
          margin: "mb-2"
        }, null, 8, ["value", "loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Banner",
          "non-text": "",
          loading: _ctx.loading,
          margin: "my-2"
        }, {
          content: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.detailCategory.banner,
              alt: "banner-materi",
              class: "max-w-72",
              title: 'Banner ' + _ctx.detailCategory.title,
              "data-action": "zoom"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Status",
          "non-text": "",
          loading: _ctx.loading
        }, {
          content: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([
                _ctx.detailCategory.isActive ? 'text-green-600' : 'text-yellow-500',
              ])
            }, _toDisplayString(_ctx.detailCategory.isActive ? "Publish" : "Draft"), 3)
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          type: "button",
          class: "button w-20 bg-theme-6 text-white ml-auto",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseClick && _ctx.onCloseClick(...args)))
        }, " Tutup ")
      ])
    ]),
    _: 1
  }, 8, ["modal-id"]))
}